<template>
  <b-row class="mt-5 match-height">
    <b-col md="3" class="">
      <b-nav pills vertical class="align-items-start">
        <b-nav-item v-for="(tab, index) in tabs" :key="index" :to="{ name: tab.route }" :active="$route.name === tab.route" class="mb-50">
          <template>
            <feather-icon
              :icon="tab.icon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold font-lg">{{ tab.title }} </span>
          </template>
        </b-nav-item>
      </b-nav>
    </b-col>

    <b-col md="9" class="p-0 m-0">
      <b-card>
        <router-view></router-view>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BTabs, BNav, BRow, BCol, BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BNav,
    BTabs,
    BCard,
    BNavItem,
    BCardBody,
    BCardHeader,
  },
  data() {
    return {
      options: {},
      tabs: [
        {
          title: 'Genesis',
          route: 'genesis',
          icon: 'UserIcon',
        },
        {
          title: 'Address',
          route: 'address',
          icon: 'MapPinIcon',
        },
        {
          title: 'Work Experience',
          route: 'work-experience',
          icon: 'BriefcaseIcon',
        },
        {
          title: 'Education History',
          route: 'education-history',
          icon: 'BookIcon',
        },
        {
          title: 'Skills',
          route: 'skills',
          icon: 'StarIcon',
        },
        {
          title: 'Voluntary Services',
          route: 'voluntary-services',
          icon: 'SunriseIcon',
        },
        {
          title: 'Hobbies & Interests',
          route: 'hobbies',
          icon: 'ThumbsUpIcon',
        },
        {
          title: 'References',
          route: 'references',
          icon: 'UsersIcon',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .font-lg {
    font-size: 13px;
  }
</style>
